import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { forwardRef } from "react";

const InsertCode = forwardRef<HTMLInputElement, any>(
  (
    {
      codeDisplayMode,
      setCodeDisplayMode,
      focus = false,
      internalCode,
      setCode,
      onGoToTicketCode,
      loading,
    },
    ref
  ) => {
    return (
      <HStack justify={"flex-end"} w={["full", "full", "initial"]}>
        {codeDisplayMode === "closed" ? (
          <Text
            cursor={"pointer"}
            color={{ base: "white", md: "gray.500" }}
            fontWeight={{ base: "bold", md: "normal" }}
            onClick={() => {
              setCodeDisplayMode("open");
            }}
          >
            Got a code?
          </Text>
        ) : null}
        {codeDisplayMode === "open" ? (
          <InputGroup>
            <Input
              autoFocus={focus}
              pr={internalCode && internalCode.length > 0 ? "3.5rem" : "2rem"}
              ref={ref}
              onChange={(e) => {
                setCode(e.currentTarget.value);
              }}
              value={internalCode}
              color={["white", "black"]}
              borderWidth={1}
              borderColor={["white", "gray.300"]}
              placeholder="Enter your code"
            />
            <InputRightElement
              pr={internalCode && internalCode.length > 0 ? "3.5rem" : "2rem"}
            >
              {internalCode && internalCode.length > 0 ? (
                <IconButton
                  onClick={(e) => {
                    setCode("");
                    onGoToTicketCode("");
                  }}
                  background={"transparent"}
                  _hover={{ background: "transparent" }}
                  isRound
                  aria-label="Clear code"
                  icon={<SmallCloseIcon color={["white", "gray.300"]} />}
                />
              ) : null}
              <Button
                h="1.75rem"
                minW="60px"
                bg="white"
                size={"sm"}
                isDisabled={
                  !internalCode || internalCode.length === 0 || loading
                }
                isLoading={loading.code}
                onClick={() => onGoToTicketCode(internalCode)}
              >
                Apply
              </Button>
            </InputRightElement>
          </InputGroup>
        ) : null}
      </HStack>
    );
  }
);

export default InsertCode;
